import React from "react"
import MainLogo from "../global/mainLogo"
import ButtonIcon from "../icon/buttonIcon"
import { Col, Container, Row } from "react-bootstrap"
import "../../sass/global/main.scss"

const WvpMain = () => {
  return (
    <div className="main">
      <Container>
        <Row>
          <Col md={12} lg={7} xl={6}>
            <div className="intro">
              <p className="service-label">WHITEBOARD VIDEO</p>
              <h1 className="title">
                That Whiteboard Video Agency You’ve Been Looking For!
              </h1>
              <p className="description">
                Are you looking for simple and affordable animation video to
                explain your idea, product or service? You’ve come to the right
                place! Scroll down and see how we can help you.
              </p>
              <a
                href="/whiteboard-video-production/#CQL"
                className="btn wowaction-btn drift-chat-popup"
              >
                <ButtonIcon />
                <span>Have a project in mind? Chat with us</span>
              </a>
            </div>
          </Col>
          <MainLogo />
        </Row>
      </Container>
    </div>
  )
}

export default WvpMain
