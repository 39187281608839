import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const WvpFaq = () => {
  return (
    <section className="whiteboard-faq" style={{ paddingTop: "0" }}>
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">
              Frequently Asked Questions on Whiteboard Video
            </h2>
            <p className="sub-desc">
              Still not convinced? Too many doubts? No problem! Here are some of
              the most frequent questions we hear from people like you, along
              with our answers. If you still have questions, please feel to let
              us know.
            </p>
          </Col>
          <Col lg={5} className="wrapper-main">
            <div className="faq-content">
              <h3>1) How much does it cost?</h3>
              <p>
                We create whiteboard videos for just around $2300 to $4000 for a
                minute. That’ll be $38 to $66 per second depending on the style
                and other process factors of the video. If you know what
                explainer videos are capable of in terms of marketing &
                conversion, then you know the ROI and what it’s worth for this
                affordable price.
              </p>
            </div>
            <div className="faq-content">
              <h3>2) What Is Your Production Process?</h3>
              <p>
                Once you shake hands with us, we’ll discuss the concept, come up
                with a script & storyboard that’ll boost the purpose of the
                video. Then, we’ll record the voiceover after choosing the right
                audio artist and then proceed to our creative animation process.
                “Impress & Convert” - that’s what we keep in mind while creating
                videos. We’ll also make sure to integrate the right kinda
                background music for your video. Oh, and yes! You’ll be having a
                dedicated project manager.
              </p>
            </div>
          </Col>
          <Col lg={5} className="offset-lg-2 wrapper-main">
          <div className="faq-content">
	      			<h3>3) How Long Will It Take To Produce?</h3>
	      			<p>The production time is depending upon the duration of the video and the complexity of the video. 
	      				Usually we can deliver a whiteboard video within 2 to 4 weeks.</p>
	      		</div>
	      		<div className="faq-content">
	      			<h3>Do You Provide Revisions?</h3>
	      			<p>Yes we do! To make sure that you get the video that you need, we are ready to incorporate your feedback and suggestion. 
	      				We can accomodate 2-3 revisions. Our team will assist you throughout the process to avoid the need for a revision in the first place!</p>
	      		</div>
	      		<div className="faq-link">
	      			<a href="/blog/inexpensive-video-marketing/" target="_blank">5) <span>How To Do Video Marketing On A Low Budget?</span></a>
	      			<a href="/blog/best-video-marketing-tools/" target="_blank">6) <span>What Are The Best Video Marketing Tools?</span></a>
	      			<a href="/blog/diy-explainer-video-tool-disadvantages/" target="_blank">7) <span>Disadvantages Of Using DIY Video Tools?</span></a>
	      			<a href="/explainer-video/" target="_blank">8) <span>All You Need to Know About Explainer Videos</span></a>
	      		</div>              
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default WvpFaq
