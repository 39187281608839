import React from "react"
import ConveyMessageIcon from "../icon/conveyMessageIcon"
import ImproveIcon from "../icon/improveIcon"

import PricingIcon from "../icon/pricingIcon"
import AttentionIcon from "../icon/attentionIcon"
import ComplexIcon from "../icon/complexIcon"
import SimpleIcon from "../icon/simpleIcon"
import { Container, Row, Col } from "react-bootstrap"
import "../../sass/global/why-you-need.scss"

const WvpWhyYouNeed = () => {
  return (
    <section className="why-you-need" style={{paddingBottom: "0"}}>
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">
              6 Reasons Why You Need A Whiteboard Video
            </h2>
            <p className="sub-desc">
              Here are the benefits of having a hand drawn whiteboard animation
              video. Well, you seriously don’t need one if these aren’t what
              you’re looking for.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <PricingIcon />
              <h3 class="col-md-7">Affordable Pricing</h3>
              <p>
                Whiteboard videos are cheaper comapred to animations and the ROI
                is mindblowing!
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <AttentionIcon />
              <h3 class="col-md-7">Grab & Retain Attention</h3>
              <p>
                As the story is being unfolded through hand drawings, it helps
                retain the interest and attention of the audience.
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <ComplexIcon />
              <h3 class="col-md-8">Best For Lengthy, Complex Topics</h3>
              <p>
                Whiteboard videos works best for explaining long and complex
                topics, thought leadership and employee onboarding.
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <ConveyMessageIcon />
              <h3 class="col-md-8">More Chances For Shares & Virality</h3>
              <p>
                Videos are the most shared content on social media. Whieboars
                videos increase your brand awareness.
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <ImproveIcon />
              <h3 class="col-md-8">Improve Your Search Rankings</h3>
              <p>
                Whiteboard videos help establish your authority over a topic and
                improves your on-page SEO.
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <SimpleIcon />
              <h3 class="col-md-7">Simple & Quick To Produce</h3>
              <p>
                Compared to other forms of animations, whiteboard videos are
                easy to produce in series or in really long durations.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default WvpWhyYouNeed
