import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import WvpMain from "../../components/whiteboard-video-production/wvpMain"
import WbvPortfolioLanding from "../../components/whiteboard-video-production/wbvPortfolioLanding"
import WvpWhyYouNeed from "../../components/whiteboard-video-production/wvpWhyYouNeed"
import CompanyLogo from "../../components/global/companyLogo"
import WvpFaq from "../../components/whiteboard-video-production/wvpFaq"
import AboutWork from "../../components/global/aboutWork"

import WvpTopCompanies from "../../components/whiteboard-video-production/wvpTopCompanies"
import AevTestimonialHighlight from "../../components/animated-explainer-video/aevTestimonialHighlight"
import HomeTestimonials from "../../components/home/homeTestimonials"
import ProjectInMind from "../../components/global/projectInMind"
import HomeForm from "../../components/home/homeForm"
import DirectContact from "../../components/global/directContact"

import whiteBoardVideoFeaturedImage from "../../../static/home//wowmakers.jpeg"

import "../../sass/pages/whiteboard-video-production.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={whiteBoardVideoFeaturedImage}
      title="Whiteboard Video Production Agency | WowMakers"
      description="We create corporate whiteboard explainer videos that meet your expectations. High-quality videos are delivered before deadlines."
    />
  </> 
)


const WhiteBoardVideoProduction = () => {
  return (
    <Layout>
      <Seo
        bclass="whiteboard-video-production"
        bid="whiteboard-video-production"
      />
      <ScrollIndicationHeightZero />
      <WvpMain />
      <WbvPortfolioLanding />
      <WvpWhyYouNeed />
      <CompanyLogo />
      <WvpFaq />
      <AboutWork />
      <WvpTopCompanies />
      <AevTestimonialHighlight />
      <HomeTestimonials />
      <ProjectInMind title="Do you have a story to tell the world?" />
      <HomeForm page="whiteboard-video"/>
      <DirectContact />
    </Layout>
  )
}

export default WhiteBoardVideoProduction
