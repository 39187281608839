import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"

const WvpTopCompanies = () => {
  return (
    <section className="top-wb-companies">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">
              Top Whiteboard Video Companies & DIY Animation Tools
            </h2>
            <p className="sub-desc">
              You are spoiled for choice when it comes to selecting the right
              whiteboard video company for you. Don’t worry, we have made your
              life easier by listing some other top agencies in this space.
            </p>
          </Col>

          <Col xs={12} className="wrapper-main">
            <div className="wbv-company-table">
              <div className="title-header clearfix">
                <Row>
                  <Col xs={3} className="d-flex align-items-center">
                    <span className="c-name-tag">Company Name</span>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span>YouTube Channel</span>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span>Price Range</span>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span>Animation Softwares</span>
                  </Col>
                </Row>
              </div>
              <div className="wbv-company-details clearfix">
                <Row>
                  <Col xs={3} className="d-flex align-items-center">
                    <div className="c-logo">
                      <StaticImage
                        src="../../images/whiteboard-video-production/DrawShop.png"
                        alt="Drawshop"
                        width={144}
                        height={64}
                        placeholder="blurred"
                      />
                    </div>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span className="c-name">
                      <a
                        href="https://www.youtube.com/user/TheDrawShop"
                        target="_blank"
                        rel="noreferrer"
                      >
                        TheDrawShop
                      </a>
                    </span>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span>$5000/sec</span>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span>
                      <Link
                        to="/blog/explainer-video-software/#VideoScribe"
                        target="_blank"
                        rel="noreferrer"
                      >
                        VideoScribe
                      </Link>
                    </span>
                  </Col>
                </Row>
              </div>
              <div className="wbv-company-details clearfix">
                <Row>
                  <Col xs={3} className="d-flex align-items-center">
                    <div className="c-logo">
                      <StaticImage
                        src="../../images/whiteboard-video-production/Ydraw.png"
                        alt="Ydraw"
                        width={144}
                        height={64}
                        placeholder="blurred"
                      />
                    </div>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span className="c-name">
                      <a
                        href="https://www.youtube.com/user/Ydraw2011"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Ydraw
                      </a>
                    </span>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span>$5000/sec</span>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span>
                      <Link
                        to="/blog/explainer-video-software/#RawShorts"
                        target="_blank"
                        rel="noreferrer"
                      >
                        RawShorts
                      </Link>
                    </span>
                  </Col>
                </Row>
              </div>
              <div className="wbv-company-details clearfix">
                <Row>
                  <Col xs={3} className="d-flex align-items-center">
                    <div className="c-logo">
                    <StaticImage
                        src="../../images/whiteboard-video-production/WizMotions.png"
                        alt="WizMotions"
                        width={144}
                        height={64}
                        placeholder="blurred"
                      />
                    </div>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span className="c-name">
                      <a
                        href="https://www.youtube.com/user/wizMotionsHQ"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Wiz Motions
                      </a>
                    </span>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span>$5000/sec</span>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span>
                      <a
                        href="https://www.animatron.com/studio/whiteboard-animation-software"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Animatron
                      </a>
                    </span>
                  </Col>
                </Row>
              </div>
              <div className="wbv-company-details clearfix">
                <Row>
                  <Col xs={3} className="d-flex align-items-center">
                    <div className="c-logo">
                    <StaticImage
                        src="../../images/whiteboard-video-production/TruScribe.png"
                        alt="TruScribe"
                        width={144}
                        height={64}
                        placeholder="blurred"
                      />
                    </div>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span className="c-name">
                      <a
                        href="https://www.youtube.com/user/TruScribeVideos"
                        target="_blank"
                        rel="noreferrer"
                      >
                        TruScribe
                      </a>
                    </span>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span>$5000/sec</span>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span>
                      <Link
                        to="/blog/explainer-video-software/#MySimpleShow"
                        target="_blank"
                        rel="noreferrer"
                      >
                        MySimpleShow
                      </Link>
                    </span>
                  </Col>
                </Row>
              </div>
              <div className="wbv-company-details clearfix">
                <Row>
                  <Col xs={3} className="d-flex align-items-center">
                    <div className="c-logo">
                    <StaticImage
                        src="../../images/whiteboard-video-production/common-craft.png"
                        alt="common craft"
                        width={144}
                        height={64}
                        placeholder="blurred"
                      />
                    </div>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span className="c-name">
                      <a
                        href="https://www.youtube.com/user/leelefever"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Common Craft
                      </a>
                    </span>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span>$5000/sec</span>
                  </Col>
                  <Col xs={3} className="d-flex align-items-center">
                    <span>
                      <Link
                        to="/blog/explainer-video-software/#Moovly"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Moovly
                      </Link>
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default WvpTopCompanies
