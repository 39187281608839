import React from "react"
import { Link } from "gatsby"
import ButtonIcon from "../icon/buttonIcon"
import WbvPortfolio from "../animated-explainer-video/wbvPortfolio"

import { Col, Container, Row } from "react-bootstrap"
import "../../sass/global/work-tab.scss"

const WbvPortfolioLanding = () => {
  return (
    <section className="portfolio-works pb-0">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">Our latest and greatest Whiteboard videos</h2>
            <p>You can use our digital storytelling expertise to explain your ideas, product or service to the world. Watch some of our successful whiteboard video production projects.</p>
          </Col>
        </Row>
        <div className="wmtab-content active">
            <WbvPortfolio />
            <Col md={12} className="mt-5 pt-5 d-flex justify-content-center">
              <Link
                  to="/works/videos/"
                  class="btn wowaction-btn"
                >
                  <ButtonIcon />
                  <span>Watch more Whiteboard Videos</span>
                </Link>            
            </Col>            
        </div>
      </Container>
    </section>
  )
}

export default WbvPortfolioLanding
